<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Ubah Penggajian'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'payrolls/'+form.id"
              :employeeID="employeeID"
              :isEdit="true"
              v-if="dataLoaded == true"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import Card from "@/view/content/Card.vue";
import Form from '@/component/payrolls/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  data() {
    return {
      form: {
        employee_id: '',
        remuneration_id: [],
        paid_date: '',
        payment_method: '',
        ammount:"",
        quantity:[],
        remuneration_type_name:[],
        remuneration_ammount:[],
        remuneration_notes:[],
      },
      employeeID:'',
      dataLoaded:false,
    };
  },

  components: {
    Card,
    Form
  },

  methods: {

    async getData() {
      this.form = await module.list('payrolls/'+ this.$route.params.id)
      this.form.remuneration_type_name = []
      this.form.remuneration_amount = []
      // this.form.remuneration_notes = []
      this.form['_method'] = 'PUT'
      this.employeeID = this.form.employee_id
      this.dataLoaded = true
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Penggajian", route: "" },
      { title: "Daftar Penggajian", route: "/payrolls/list" },
      { title: "Ubah" },
    ])
    this.getData()
  },

}

</script>